import React from 'react'

const ContenedorEstetica = (props) => {
  return (
    <div className='w-3/6 invisible__responsive justify-center flex items-center'>

      <div className="w-60 h-100 rounded-3xl mt-4 mb-4 ml-32 shadow-lg shadow-electry-blue p-4">
        <img className="rounded-3xl" src={props.img} alt="" />
      </div>

    </div>

  )
}

export default ContenedorEstetica
