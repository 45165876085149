import React, { useEffect} from 'react'
import { BrowserRouter,Navigate} from 'react-router-dom'
import { useStateContext  } from './contexts/ContextProvider'
import {RutasDasboard } from './rutas';


export const App = () => {
  const { setCurrentColor, currentMode, setCurrentMode} = useStateContext();
  
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode')
    const currentThemeMode = localStorage.getItem('themeMode')
    if( currentThemeColor, currentThemeMode) {
      setCurrentColor(currentThemeColor)
      setCurrentMode(currentThemeMode)
    }
  }, []);

  const authStatus="authenticatedd";

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <RutasDasboard/>
      </BrowserRouter>
    </div>
  )
}
