import { createSlice } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'


export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
  },
  reducers: {
    obligatorios__formulario: (state) => {
      state.value=0;
      
      let testElements = document.getElementsByClassName("obligatorios__globales");
      let testDivs = Array.prototype.filter.call(
        testElements,
        function (testElement) {
          if(testElement.textContent!="" && testElement.textContent!=" " && testElement.textContent!=undefined){
            state.value++;
          }
        },
      );
      
      if(state.value>0){

        const mostrarAlerta=()=>{
            Swal.fire({
              title: "Campos Obligatorios",
              icon: "error",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "Aceptar",
              confirmButtonColor: '#0e5154',
            });
        }

        mostrarAlerta();
      }

    },
  },
})

// Action creators are generated for each case reducer function
export const { obligatorios__formulario } = counterSlice.actions

export default counterSlice.reducer