import ReactDOM from 'react-dom'
import './index.css'
import './App.css'
import { App } from './App'
import { ContextProvider } from './contexts/ContextProvider'
import { store } from './app/store'
import { Provider } from 'react-redux'


ReactDOM.render(
    <Provider store={store}>
        <ContextProvider>
            <App/>
        </ContextProvider>
    </Provider>,
    document.getElementById('root')
);