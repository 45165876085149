import React from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { obligatorios__formulario } from '../features/counter/counterSlice'
import imagenEstetica from  '../data/logoEsteticaFondo.jpg';
import ContenedorEstica from "../components/ContenedorEstetica";
import { FormGroup} from "reactstrap";
import axios from 'axios';
import Swal from 'sweetalert2'

const RegistroEmpresas = () => {

  const VALID_PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;

  const count = useSelector((state) => state.counter.value);
  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    ruc: Yup.string().required("Requerido ingresar ruc"),
    empresa: Yup.string().required("Requerido ingresar nombre de la empresa"),
    direccion: Yup.string().required("Requerido ingresar dirección de la empresa"),
    email: Yup.string().required("Requerido ingresar email de la empresa").email("Correo electronico ingresado no válido"),
    telefono: Yup.string().required("Requerido ingresar telefono de la empresa"),
    contrasena: Yup.string().required('Contraseña reqerida').min(8, 'La contraseña debe tener al menos 8 caracteres').max(20, 'La contraseña no debe superar los 20 caracteres'),
    contrasena__dos: Yup.string().required('Confirmación contraseña requerida').oneOf([Yup.ref('contrasena'), null], 'Contraseña no coincide'),
  });



  return (
    <div className='mt-4 p-2 pb-4  bg-gray-200 w-full flex h-full'>
      <div className="md:w-2/5 w-full__responsive  rounded-3xl lg:px-6md:px-2 bg-gray-100 shadow-lg shadow-color__shadow p-4 margin__top__contenedor">

        <label className="block mt-3 text-xl text-gray-700 text-center font-semibold">
          Registro de Empresa
        </label>

        <Formik
          initialValues={{
            ruc: "",
            empresa:"",
            direccion:"",
            email:"",
            telefono:"",
            contrasena:"",
            contrasena__dos:"",
          }}
          validationSchema={formSchema}
          onSubmit={(values, { resetForm }) => {
            axios({
              method: 'POST',
              url: 'https://nano-com.preview-domain.com/api/empresas',
              data: values
            }).then(function (res) {

              Swal.fire({
                title: "Registro realizado satisfactoriamente",
                icon: "success",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar",
                confirmButtonColor: '#0e5154',
                allowOutsideClick: false,
              }).then((result) => {
                  resetForm();
              });
            }).catch(function (res) {
                Swal.fire({
                  title: "Usuario ya existe",
                  icon: "error",
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: '#0e5154',
                });
            });
          }}

        >
        {props => (
          <Form className='mt-10 w-full'>

            <FormGroup>
              <label htmlFor='ruc' className='text-sm'>RUC: </label>
              <Field
                type="text"
                className='form-control mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0 pl-3'
                name='ruc'
                placeholder='Ingrese Ruc de la empresa' 
                value={props.values.ruc}
                onChange={(e)=>{
                  var ExpRegSoloNumeros="^[0-9]+$";
                  if(e.currentTarget.value.match(ExpRegSoloNumeros)!=null && e.currentTarget.value.length<=15){
                    props.handleChange(e);
                  }
                }}
                onBlur={(e)=>{
                  var ExpRegSoloNumeros="^[0-9]+$";
                  if(e.currentTarget.value.match(ExpRegSoloNumeros)!=null && e.currentTarget.value.length<=15){
                    props.handleChange(e);
                  }
                }}
                
              />
              <ErrorMessage
                name='ruc'
                component='div'
                className="bg-red-100 border mt-1 border-red-400 text-red-700 px-1 py-1 rounded relative text-sm obligatorios__globales" role="alert"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor='empresa' className='text-sm'>EMPRESA: </label>
              <Field
                type="text"
                className='form-control mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0 pl-3 uppercase'
                name='empresa'
                placeholder='Ingrese nombre de la empresa'
              />
              <ErrorMessage
                name='empresa'
                component='div'
                className="bg-red-100 border mt-1 border-red-400 text-red-700 px-1 py-1 rounded relative text-sm obligatorios__globales" role="alert"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor='direccion' className='text-sm'>DIRECCIÓN: </label>
              <Field
                type="text"
                className='form-control mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0 pl-3'
                name='direccion'
                placeholder='Ingrese dirección de la empresa'
              />
              <ErrorMessage
                name='direccion'
                component='div'
                className="bg-red-100 border mt-1 border-red-400 text-red-700 px-1 py-1 rounded relative text-sm obligatorios__globales" role="alert"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor='email' className='text-sm'>EMAIL: </label>
              <Field
                type="text"
                className='form-control mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0 pl-3'
                name='email'
                placeholder='Ingrese email de la empresa'
              />
              <ErrorMessage
                name='email'
                component='div'
                className="bg-red-100 border mt-1 border-red-400 text-red-700 px-1 py-1 rounded relative text-sm obligatorios__globales" role="alert"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor='telefono' className='text-sm'>TELÉFONO: </label>
              <Field
                type="text"
                className='form-control mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0 pl-3'
                name='telefono'
                placeholder='Ingrese telefono de la empresa'
                value={props.values.telefono}
                onChange={(e)=>{
                  var ExpRegSoloNumeros="^[0-9]+$";
                  if(e.currentTarget.value.match(ExpRegSoloNumeros)!=null && e.currentTarget.value.length<=10){
                    props.handleChange(e);
                  }
                }}
                onBlur={(e)=>{
                  var ExpRegSoloNumeros="^[0-9]+$";
                  if(e.currentTarget.value.match(ExpRegSoloNumeros)!=null && e.currentTarget.value.length<=10){
                    props.handleChange(e);
                  }
                }}
              />
              <ErrorMessage
                name='telefono'
                component='div'
                className="bg-red-100 border mt-1 border-red-400 text-red-700 px-1 py-1 rounded relative text-sm obligatorios__globales" role="alert"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor='contrasena' className='text-sm'>CONTRASEÑA: </label>
              <Field
                type="password"
                className='form-control mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0 pl-3'
                name='contrasena'
                placeholder='Ingrese contraseña'
              />
              <ErrorMessage
                name='contrasena'
                component='div'
                className="bg-red-100 border mt-1 border-red-400 text-red-700 px-1 py-1 rounded relative text-sm obligatorios__globales" role="alert"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor='contrasena__dos' className='text-sm'>CONFIRMAR CONTRASEÑA: </label>
              <Field
                type="password"
                className='form-control mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0 pl-3'
                name='contrasena__dos'
                placeholder='Ingrese confirmación contraseña'
              />
              <ErrorMessage
                name='contrasena__dos'
                component='div'
                className="bg-red-100 border mt-1 border-red-400 text-red-700 px-1 py-1 rounded relative text-sm obligatorios__globales" role="alert"
              />
            </FormGroup>

            <div className="mt-7 mb-7">

              <button 
                className="bg-vermillon w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105" 
                type='submit'
                onClick={() => dispatch(obligatorios__formulario())}
              >
                Registrar
              </button>

            </div>

          </Form>

          )}

        </Formik>

      </div>

      <ContenedorEstica img={imagenEstetica}/>

    </div>
  )
}

export default RegistroEmpresas 